<template>
  <default>
     <v-chip-group mandatory active-class=" primary">
            <v-chip active-class="false" :to="{ name: 'Dashboard' }">Dashboard
            </v-chip> <span class="mt-2 mx-2"> > </span>
           <v-chip active-class="false"  :to="{ name: 'Products' }">Products
            </v-chip> <span class="mt-2 mx-2"> > </span>
           <v-chip :to="{ name: 'AkibaPlan' }">
            {{ $options.name }}
            </v-chip>
        </v-chip-group>
    <v-card elevation="2" class="tw-px-2 md:tw-px-6 tw-mt-2 md:tw-mt-10">
      <card-title icon="/images/icons/top-icon.png">Akiba Halisi Plan Calculator</card-title>
      <div class="tw-w-full tw-flex tw-flex-wrap tw-py-3 md:tw-py-6">
        <keep-alive>
          <component
          :is="current"
          :productData="productData"
          :benefits="benefits"
          :terms="terms"
          :quoteSummaryData="quoteSummaryData"
          @to-quote-summary="toQuoteSummary"
        ></component>
        </keep-alive>
      </div>
    </v-card>
  </default>
</template>

<script>
import Default from '../../layouts/Default';
import CardTitle from '../../components/CardTitle';
import BackButton from '../../components/BackButton';
import QuoteSummary from '../../components/rfq/QuoteSummary';
import AkibaClientQuotation from '../../components/rfq/akiba-halisi/Quote';
import ProductMixin from '@/mixins/ProductMixin';
import savedQuoteMixin from '../../mixins/savedQuoteMixin';

export default {
  name: 'AkibaHalisiPlan',
  metaInfo: {
    title: 'RFQ - Akiba Plan',
  },
  components: {
    QuoteSummary, BackButton, CardTitle, Default, AkibaClientQuotation,
  },
  mixins: [ProductMixin, savedQuoteMixin],
  data() {
    return {
      current: AkibaClientQuotation,
      productData: { prodCode: 2013102 },
      benefits: [
        { coverName: 'Accidental Death Benefit', coverProdCode: 2013497 },
        { coverName: 'Total & Permanent Disability', coverProdCode: 2013496 },
        { coverName: 'Waiver of Premium', coverProdCode: 2013495 },
        { coverName: 'Medical Reimbursement', coverProdCode: 2013499 },
        { coverName: 'Critical Illness', coverProdCode: 2013498 },
        { coverName: 'Retrenchment', coverProdCode: 2016609 },
      ],
      terms: [
        { years: 6, optionCode: 2016206 },
        { years: 9, optionCode: 2016207 },
        { years: 12, optionCode: 2012141 },
        { years: 15, optionCode: 2012142 },
        { years: 18, optionCode: 2012143 },
      ],
      quoteSummaryData: {},
    };
  },
  mounted() {
    this.checkSavedQuote('akiba');
  },
};
</script>
